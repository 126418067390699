import React from "react"
import SEO from "../components/seo"
import CountUp from "react-countup"
import Title from "../components/title"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import ScrollAnimation from "react-animate-on-scroll"
import { Row, Col, Container, ListGroup } from "react-bootstrap"

const About = () => {
  const data = useStaticQuery(graphql`
    {
      allJsonJson {
        nodes {
          expirience {
            subtitle
            title
            time
          }
          education {
            subtitle
            title
          }
          capabilities
          tools
        }
      }
    }
  `)

  return (
    <Layout className="about">
      <SEO title="About" />
      <Title
        title="About Me"
        description="Hi, I am jayadevan, a passionate visual designer with handful of experience in UI/UX , 2d Animation, Graphic Design and expertise in all advanced aspects of UI modern trends, Design & Color Principles in a wide array of applications using latest and industry standard softwares. Can manage workflows with ease and deliver quality content within a given time, capable to create traditional and new design styles . I love to create clean, smart & minimalist designs."
      />
      <div className="details-wrap">
        <Container>
          <Row>
            <Col md={6}>
              <h3>Experience</h3>
              <ListGroup as="ul" className="pr-4">
                {data.allJsonJson.nodes[1].expirience.map((item, idx) => {
                  return (
                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOnce={true}
                      key={idx}
                    >
                      <ListGroup.Item as="li">
                        <strong>{item.title}</strong>
                        <span>{item.subtitle}</span>
                        <small>{item.time}</small>
                      </ListGroup.Item>
                    </ScrollAnimation>
                  )
                })}
              </ListGroup>
            </Col>
            <Col md={6}>
              <h3>Education</h3>
              <ListGroup as="ul" className="pr-4">
                {data.allJsonJson.nodes[1].education.map((item, idx) => {
                  return (
                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOnce={true}
                      key={idx}
                    >
                      <ListGroup.Item as="li">
                        <strong>{item.title}</strong>
                        <span>{item.subtitle}</span>
                      </ListGroup.Item>
                    </ScrollAnimation>
                  )
                })}
              </ListGroup>
            </Col>
            <Col md={6}>
              <h3>Capabilities</h3>
              <ListGroup as="ul" className="pd-0 pr-4 mt-4">
                {data.allJsonJson.nodes[1].capabilities.map((item, idx) => {
                  return (
                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOnce={true}
                      key={idx}
                    >
                      <ListGroup.Item as="li">
                        <span>{item}</span>
                      </ListGroup.Item>
                    </ScrollAnimation>
                  )
                })}
              </ListGroup>
            </Col>
            <Col md={6}>
              <h3>Tools</h3>
              <ListGroup as="ul" className="pd-0 pr-4 mt-4">
                {data.allJsonJson.nodes[1].tools.map((item, idx) => {
                  return (
                    <ScrollAnimation
                      animateIn="fadeInUp"
                      animateOnce={true}
                      key={idx}
                    >
                      <ListGroup.Item as="li">
                        <span>{item}</span>
                      </ListGroup.Item>
                    </ScrollAnimation>
                  )
                })}
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="count-down">
        <Container>
          <ListGroup>
            <ListGroup.Item>
              <CountUp end={150} duration={5} />
              <small>websites</small>
            </ListGroup.Item>
            <ListGroup.Item>
              <CountUp end={8} duration={5} />
              <small>Mobile/ Web Apps</small>
            </ListGroup.Item>
            <ListGroup.Item>
              <CountUp end={6} duration={5} />
              <small>Branding Solutions</small>
            </ListGroup.Item>
            <ListGroup.Item>
              <CountUp end={100} duration={5} />
              <small>Minutes Animation</small>
            </ListGroup.Item>
          </ListGroup>
        </Container>
      </div>
    </Layout>
  )
}

export default About
